import React, { useContext } from "react";
import Step from "./Step";
import { GeneralContext } from "../App";

export default function Steps({ width = "100%" }) {
  const { step } = useContext(GeneralContext);

  return (
    <div className={`steps steps-${step}`} style={{ width: width }}>
      <Step currstep={step} step={1}>
        Enter video URL
      </Step>
      <span className="line line-1"></span>
      <Step currstep={step} step={2}>
        Select highlights
      </Step>
      <span className="line line-2"></span>
      <Step currstep={step} step={3}>
        Create highlight clip and TFB account
      </Step>
    </div>
  );
}
