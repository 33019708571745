import { useContext } from "react";
import axios from "axios";
import _ from "lodash";

import { GeneralContext } from "../App";

export default function VeoUrlForm({ placeholder = "", children, onNextStep }) {
  const {
    step,
    url,
    onChangeUrl,
    onSetHighlights,
    onSetVideoUrl,
    onSetError,
    onSetIsLoading,
    isLoading,
  } = useContext(GeneralContext);

  function handleSubmit(e) {
    if (!url) alert("Please enter a valid URL");

    e.preventDefault();
    if (url) {
      getHighlights(url);
      onChangeUrl("");
      onNextStep();
    }
  }
  async function getHighlights(url) {
    try {
      onSetIsLoading(true);
      const response = await axios(
        `https://api.thefootballbrain.app/veo/get_veo_video?url=${url}`
      );
      console.log(response);
      // if (!response)
      if (!response.data.success) throw new Error(response.data.error_code);

      let highlights = response.data.highlights;

      _.map(highlights, (hlt) => {
        return (hlt.checked = false);
      });
      _.map(highlights, (hlt) => {
        return (hlt.highlight_tag = `${hlt.second_start}${hlt.second_final}`);
      });

      onSetHighlights(highlights.reverse());
      onSetVideoUrl(response.data.video_url);
    } catch (error) {
      console.log(error.message);
      alert(error.message);
      // onSetError(error.message);
    } finally {
      // onSetError("");
      onSetIsLoading(false);
    }
  }

  return (
    <div className={`veo-url-form veo-url-form-${step}`}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          value={url}
          onChange={(e) => onChangeUrl(e.target.value)}
          type="text"
          placeholder={placeholder}
          name="url"
        ></input>
        {children}
      </form>
    </div>
  );
}
