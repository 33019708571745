import { useContext, useEffect, useRef } from "react";
import { GeneralContext } from "../App";

export default function VideoPlayer() {
  const { videoUrl, currentVideoTime, onSetCurrentVideoTime, videoRef } =
    useContext(GeneralContext);

  // console.log(videoUrl, typeof videoUrl);

  // useEffect(() => {
  //   videoPlayer.current.seekTo();
  // }, [currentTime]);
  // const handleGoToMinute = (startPoint) => {
  //   // Get reference to video time

  //   const videoElement = videoRef.current;

  //   // Check if reference exists and if the video is loaded
  //   if (videoElement && videoElement.readyState >= videoElement.HAVE_METADATA) {
  //     // Calculate no of seconds corresponding to desired timestamp
  //     const seconds = startPoint;

  //     // Set currentTime to the computed value(in seconds)
  //     videoElement.currentTime = seconds;
  //     videoElement.play();
  //   }
  // };
  return (
    <div className="video-player">
      <video
        ref={videoRef}
        type="video/mp4"
        src={videoUrl}
        width="320"
        height="240"
        controls
        muted
        onTimeUpdate={(e) => onSetCurrentVideoTime(e.target.currentTime)}
      >
        Your browser does not support HTML video
      </video>
    </div>
  );
}
