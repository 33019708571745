import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./page2.css";
import "./page3.css";
import "./help.css";
import "./generalMobile.css";
import App from "./App.js";
// import StarRating from "./StarRating";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
