export default function TFBLogo({ color = "white" }) {
  return (
    <div className="tfb-logo">
      <img
        src={require(`./tfb-logo-${color}.png`)}
        alt="The football brain Logo"
      ></img>
    </div>
  );
}
