export default function ExampleHelp({ onSetShowHelp }) {
  return (
    <div className="example-help">
      <p>
        Example URL:
        https://app.veo.co/matches/20230812-2009-csa-steaua-b1028a0b/
      </p>
      <button onClick={onSetShowHelp} className="btn-help">
        ?
      </button>
    </div>
  );
}
