import { createContext, useState, useRef } from "react";
import FirstPage from "./components/FirstPage";
import SecondPage from "./components/SecondPage";
import ThirdPage from "./components/ThirdPage";
import Error from "./components/Error";

export const GeneralContext = createContext(null);

// const sampleHighlights = [
//   {
//     video_tag_title: "Half start",
//     id_video_tag: "39019",
//     second_start: "176",
//     second_final: "176",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39096",
//     second_start: "220",
//     second_final: "245",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Kickoff",
//     id_video_tag: "39095",
//     second_start: "221",
//     second_final: "246",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39094",
//     second_start: "262",
//     second_final: "287",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39093",
//     second_start: "278",
//     second_final: "303",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39092",
//     second_start: "354",
//     second_final: "379",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39091",
//     second_start: "395",
//     second_final: "420",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39090",
//     second_start: "507",
//     second_final: "532",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39089",
//     second_start: "518",
//     second_final: "543",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39088",
//     second_start: "618",
//     second_final: "643",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39087",
//     second_start: "642",
//     second_final: "667",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39086",
//     second_start: "738",
//     second_final: "763",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39085",
//     second_start: "848",
//     second_final: "873",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39084",
//     second_start: "875",
//     second_final: "900",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39083",
//     second_start: "886",
//     second_final: "911",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39082",
//     second_start: "965",
//     second_final: "990",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39081",
//     second_start: "1051",
//     second_final: "1076",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39080",
//     second_start: "1088",
//     second_final: "1113",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39079",
//     second_start: "1254",
//     second_final: "1279",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39078",
//     second_start: "1268",
//     second_final: "1293",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39077",
//     second_start: "1288",
//     second_final: "1313",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39076",
//     second_start: "1341",
//     second_final: "1366",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39075",
//     second_start: "1439",
//     second_final: "1464",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39074",
//     second_start: "1549",
//     second_final: "1574",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39073",
//     second_start: "1550",
//     second_final: "1575",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39072",
//     second_start: "1575",
//     second_final: "1600",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39071",
//     second_start: "1598",
//     second_final: "1623",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39070",
//     second_start: "1664",
//     second_final: "1689",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39069",
//     second_start: "1693",
//     second_final: "1718",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39068",
//     second_start: "1751",
//     second_final: "1776",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39067",
//     second_start: "1787",
//     second_final: "1812",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal",
//     id_video_tag: "39066",
//     second_start: "1789",
//     second_final: "1814",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Kickoff",
//     id_video_tag: "39065",
//     second_start: "1833",
//     second_final: "1858",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39064",
//     second_start: "1959",
//     second_final: "1984",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39063",
//     second_start: "2029",
//     second_final: "2054",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39062",
//     second_start: "2093",
//     second_final: "2118",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39061",
//     second_start: "2180",
//     second_final: "2205",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39060",
//     second_start: "2227",
//     second_final: "2252",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39059",
//     second_start: "2306",
//     second_final: "2331",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39058",
//     second_start: "2552",
//     second_final: "2577",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39057",
//     second_start: "2597",
//     second_final: "2622",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39056",
//     second_start: "2619",
//     second_final: "2644",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39055",
//     second_start: "2714",
//     second_final: "2739",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39054",
//     second_start: "2790",
//     second_final: "2815",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39053",
//     second_start: "2790",
//     second_final: "2815",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39052",
//     second_start: "2844",
//     second_final: "2869",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Kickoff",
//     id_video_tag: "39051",
//     second_start: "2872",
//     second_final: "2897",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Half end",
//     id_video_tag: "39018",
//     second_start: "2888",
//     second_final: "2888",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Half start",
//     id_video_tag: "39017",
//     second_start: "3785",
//     second_final: "3785",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39050",
//     second_start: "4068",
//     second_final: "4093",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39049",
//     second_start: "4151",
//     second_final: "4176",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39048",
//     second_start: "4327",
//     second_final: "4352",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39047",
//     second_start: "4447",
//     second_final: "4472",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Corner",
//     id_video_tag: "39046",
//     second_start: "4470",
//     second_final: "4495",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39045",
//     second_start: "4486",
//     second_final: "4511",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39044",
//     second_start: "4663",
//     second_final: "4688",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39043",
//     second_start: "4808",
//     second_final: "4833",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39042",
//     second_start: "4897",
//     second_final: "4922",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39041",
//     second_start: "4963",
//     second_final: "4988",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal",
//     id_video_tag: "39040",
//     second_start: "4964",
//     second_final: "4989",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Kickoff",
//     id_video_tag: "39039",
//     second_start: "5018",
//     second_final: "5043",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39038",
//     second_start: "5181",
//     second_final: "5206",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Corner",
//     id_video_tag: "39037",
//     second_start: "5251",
//     second_final: "5276",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Corner",
//     id_video_tag: "39036",
//     second_start: "5330",
//     second_final: "5355",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Corner",
//     id_video_tag: "39035",
//     second_start: "5360",
//     second_final: "5385",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39033",
//     second_start: "5635",
//     second_final: "5660",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39034",
//     second_start: "5635",
//     second_final: "5660",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39032",
//     second_start: "5673",
//     second_final: "5698",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39031",
//     second_start: "5699",
//     second_final: "5724",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39030",
//     second_start: "5745",
//     second_final: "5770",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39029",
//     second_start: "5882",
//     second_final: "5907",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39028",
//     second_start: "5963",
//     second_final: "5988",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39027",
//     second_start: "6134",
//     second_final: "6159",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39026",
//     second_start: "6173",
//     second_final: "6198",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Corner",
//     id_video_tag: "39025",
//     second_start: "6276",
//     second_final: "6301",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39024",
//     second_start: "6278",
//     second_final: "6303",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39023",
//     second_start: "6370",
//     second_final: "6395",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Goal kick",
//     id_video_tag: "39022",
//     second_start: "6398",
//     second_final: "6423",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Freekick",
//     id_video_tag: "39021",
//     second_start: "6475",
//     second_final: "6500",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Shot on goal",
//     id_video_tag: "39020",
//     second_start: "6674",
//     second_final: "6699",
//     id_video: "1022",
//     checked: false,
//   },
//   {
//     video_tag_title: "Half end",
//     id_video_tag: "39016",
//     second_start: "6704",
//     second_final: "6704",
//     id_video: "1022",
//     checked: false,
//   },
// ];

export default function App() {
  const [step, setStep] = useState(1);
  const [url, setUrl] = useState("");
  const [highlights, setHighlights] = useState([]);
  const [selectedHighlights, setSelectedHighlights] = useState([]);
  const [filteredHighlights, setFilteredHighlights] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [filter, setFilter] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [currentVideoTime, setCurrentVideoTime] = useState("");
  const [error, setError] = useState("");
  const [activeHighlight, setActiveHighlight] = useState(false);
  const [clientMail, setClientMail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const videoRef = useRef(null);

  function reset() {
    setFilteredHighlights([]);
    setSelectedHighlights([]);
    setHighlights([]);
  }

  function handleStepChange(step) {
    if (step === 2 && !videoUrl) return;
    if (step === 3 && !selectedHighlights.length) return;
    console.log(selectedHighlights);
    setStep(step);
  }

  function handleNextStep() {
    if (!error) setStep((step) => step + 1);
  }

  return (
    <GeneralContext.Provider
      value={{
        step: step,
        onStepChange: handleStepChange,
        onNextStep: handleNextStep,
        url,
        onChangeUrl: setUrl,
        highlights,
        onSetHighlights: setHighlights,
        checkAll,
        onCheckAll: setCheckAll,
        filter,
        onSelectFilter: setFilter,
        videoUrl,
        onSetVideoUrl: setVideoUrl,
        currentVideoTime,
        onSetCurrentVideoTime: setCurrentVideoTime,
        error,
        onSetError: setError,
        videoRef,
        activeHighlight,
        onSetActiveHighlight: setActiveHighlight,
        selectedHighlights,
        onSetSelectedHighlights: setSelectedHighlights,
        filteredHighlights,
        onSetFilteredHighlights: setFilteredHighlights,
        clientMail,
        onSetClientMail: setClientMail,
        reset,
        isLoading,
        onSetIsLoading: setIsLoading,
      }}
    >
      <div>
        {/* {error && <Error message={error} />} */}
        {step === 1 && <FirstPage />}
        {step === 2 && <SecondPage />}
        {step === 3 && <ThirdPage />}
      </div>
    </GeneralContext.Provider>
  );
}
