import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import ExampleHelp from "./ExampleHelp";
import Heading from "./Heading";
import Rectangle from "./Rectangle";

import Steps from "./Steps";
import TFBLogo from "./TFBLogo";
// import VeoCamera from "./VeoCamera";
import VeoUrlForm from "./VeoUrlForm";
import Help from "./Help";
import { GeneralContext } from "../App";

export default function FirstPage() {
  const [showHelp, setShowHelp] = useState(false);

  const { onNextStep, onSetHighlights } = useContext(GeneralContext);

  function handleNext(e) {
    onNextStep();
  }

  function handleShowHelp() {
    setShowHelp((cur) => !cur);
  }

  useEffect(
    function () {
      document.addEventListener("keydown", function (e) {
        if (showHelp && e.key === "Escape") setShowHelp(false);
      });
    },
    [showHelp]
  );

  return (
    <>
      <div
        style={showHelp ? { justifyContent: "start", gap: "10px" } : {}}
        className="first-page"
      >
        <TFBLogo />
        {showHelp ? (
          <Help onHideHelp={handleShowHelp} />
        ) : (
          <>
            <Rectangle>
              <Heading />
              <Steps />
              <VeoUrlForm
                placeholder="Enter your VEO video URL"
                onNextStep={handleNext}
              >
                <Button type={"submit"}>Next step</Button>
              </VeoUrlForm>
              <ExampleHelp onSetShowHelp={handleShowHelp} />
            </Rectangle>
          </>
        )}
      </div>
    </>
  );
}
