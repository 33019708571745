export default function Heading() {
  return (
    <div className="heading">
      <h2>
        Combine multiple highlights from your{" "}
        <span>
          <img className="veo-logo" src={require("./veo.png")} alt="veo"></img>
        </span>{" "}
        video
      </h2>
    </div>
  );
}
