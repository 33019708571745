import { useState, useContext, useEffect } from "react";

import { GeneralContext } from "../App";
import _ from "lodash";

function timeStartToFinish(secondStart, secondFinish) {
  const hoursStart = Math.floor(secondStart / 3600)
    .toString()
    .padStart(2, "0");
  const minutesStart = Math.floor((secondStart % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const secondsStart = Math.floor((secondStart % 3600) % 60)
    .toString()
    .padStart(2, "0");
  const hoursEnd = Math.floor(secondFinish / 3600)
    .toString()
    .padStart(2, "0");
  const minutesEnd = Math.floor((secondFinish % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const secondEnd = Math.floor((secondFinish % 3600) % 60)
    .toString()
    .padStart(2, "0");

  return `${hoursStart}:${minutesStart}:${secondsStart} - ${hoursEnd}:${minutesEnd}:${secondEnd}`;
}

export default function Highlight({ hlt, i }) {
  const {
    onSetHighlights,
    highlights,
    onCheckAll,
    videoRef,
    activeHighlight,
    onSetActiveHighlight,
    filteredHighlights,
    onSetSelectedHighlights,
  } = useContext(GeneralContext);

  const time = timeStartToFinish(hlt.second_start, hlt.second_final);

  function handleCheck(e) {
    let allFilteredHlts = [...filteredHighlights];

    allFilteredHlts[i].checked = e.target.checked;

    _.every(allFilteredHlts, (hlt) => hlt.checked === true)
      ? onCheckAll(true)
      : onCheckAll(false);

    onSetHighlights((hlts) =>
      _.map(hlts, (hlt) =>
        hlt.highlight_tag === allFilteredHlts[i].highlight_tag
          ? { ...hlt, checked: e.target.checked }
          : hlt
      )
    );
    onSetSelectedHighlights(_.filter(highlights, (h) => h.checked === true));
  }

  function handleGoToMinute(startPoint) {
    // Get reference to video time

    const videoElement = videoRef.current;

    // Check if reference exists and if the video is loaded
    if (videoElement && videoElement.readyState >= videoElement.HAVE_METADATA) {
      // Calculate no of seconds corresponding to desired timestamp
      const seconds = startPoint;

      // Set currentTime to the computed value(in seconds)
      videoElement.currentTime = seconds;
      videoElement.play();
    }
  }
  function handlePlay() {
    handleGoToMinute(hlt.second_start);
    onSetActiveHighlight(i);
  }

  return (
    <li>
      <div
        className={
          activeHighlight === i ? "highlight highlight-active" : "highlight"
        }
      >
        <input
          type="checkbox"
          checked={hlt.checked}
          onChange={(e) => handleCheck(e)}
        />
        <div className="type-time">
          <p className="type">{hlt.video_tag_title}</p>
          <p className="time">{time}</p>
        </div>
        <img src={require("./play.jpg")} alt="play" onClick={handlePlay}></img>
      </div>
    </li>
  );
}

//
