import _ from "lodash";
import { useContext } from "react";
import { GeneralContext } from "../App";

export default function FilterHighlights() {
  const { highlights, filter, onSelectFilter, onCheckAll } =
    useContext(GeneralContext);

  const filters = [...new Set(_.map(highlights, (h) => h.video_tag_title))];

  function handleFilterChange(e) {
    onSelectFilter(e.target.value);
    onCheckAll(false);
  }

  return (
    <select value={filter} onChange={handleFilterChange}>
      <option value="">Filter Highlights ⬇</option>
      {filters.map((filter, i) => (
        <option value={filter} key={i}>
          {filter}{" "}
        </option>
      ))}
    </select>
  );
}
