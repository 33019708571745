import { CircularProgress } from "@mui/material";

function Loader({ size }) {
  return (
    <div style={{ padding: "25px", display: "flex", justifyContent: "center" }}>
      <CircularProgress size={size} />
    </div>
  );
}

export default Loader;
