import Button from "./Button";

export default function Help({ onHideHelp }) {
  return (
    <>
      <div className="overlay" onClick={onHideHelp}></div>
      <div className="help-container">
        <h1>How to get your video URL</h1>
        <div className="help-steps">
          <div className="img1">
            <p>
              {" "}
              1. In the <strong>VEO web app</strong> go to Recordings:
            </p>
            <img src={require("./help-img-1.png")} alt="instructional"></img>
          </div>
          <div>
            <p>
              2. Click on a <strong>Recording</strong>:
            </p>
            <img src={require("./help-img-2.png")} alt="instructional"></img>
          </div>
          <div>
            <p>
              3. <strong>Copy the URL</strong> of the page:
            </p>
            <img src={require("./help-img-3.png")} alt="instructional"></img>
          </div>
        </div>
        <div className="btn-container">
          <Button onClick={onHideHelp} className="btn">
            OK
          </Button>
        </div>
      </div>
    </>
  );
}
