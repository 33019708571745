import { useContext } from "react";
import { GeneralContext } from "../App";

export default function Step({ step, children, currstep }) {
  const { onStepChange } = useContext(GeneralContext);

  return (
    <div
      className={`step step-${step} ${currstep >= step ? "step-green" : ""}`}
    >
      <button onClick={() => onStepChange(step)}>{step}</button>
      <p>{children}</p>
    </div>
  );
}
