import { useContext } from "react";
import axios from "axios";
import _ from "lodash";

import { GeneralContext } from "../App";

export default function VeoUrlForm({
  onSubmit,
  placeholder = "",
  children,
  onNextStep,
}) {
  const { step, clientMail, onSetClientMail } = useContext(GeneralContext);

  return (
    <div className={`veo-url-form veo-url-form-${step}`}>
      <form onSubmit={onSubmit}>
        <input
          value={clientMail}
          onChange={(e) => onSetClientMail(e.target.value)}
          type="text"
          placeholder={placeholder}
          name="url"
        ></input>
        {children}
      </form>
    </div>
  );
}
