import { useContext, useEffect, useState } from "react";
import Highlight from "./Highlight";
import _ from "lodash";

import map from "lodash/map";
import { GeneralContext } from "../App";
import { all } from "axios";
import Loader from "./Loader";

export default function HighlightsList() {
  const {
    highlights,
    onSetHighlights,
    checkAll,
    onCheckAll,
    filter,
    onSetSelectedHighlights,
    selectedHighlights,
    onSetFilteredHighlights,
    filteredHighlights,
    isLoading,
  } = useContext(GeneralContext);

  const _ = require("lodash");

  useEffect(() => {
    onSetFilteredHighlights(
      _.filter(highlights, (h) => (!filter ? h : h.video_tag_title === filter))
    );
  }, [filter, _, highlights, onSetFilteredHighlights]);

  function handleCheckAll(e) {
    onCheckAll(e.target.checked);
    const allHlts = [...filteredHighlights];

    _.map(allHlts, (hlt) => {
      return (hlt.checked = e.target.checked);
    });

    onSetHighlights((hlts) =>
      _.map(hlts, (hlt) =>
        _.some(allHlts, (h) => h.highlight_tag === hlt.highlight_tag)
          ? { ...hlt, checked: e.target.checked }
          : hlt
      )
    );

    onSetSelectedHighlights(_.filter(highlights, (h) => h.checked === true));
  }
  // console.log(highlights);
  // console.log(filteredHighlights);
  // console.log(selectedHighlights);
  // useEffect(function () {
  //   const newSelectedHighlights = _.map(filteredHighlights, (hlt) => {
  //     return (hlt.checked = e.target.checked);
  //   });
  //   onSetSelectedHighlights(newSelectedHighlights);
  // }, []);

  return (
    <>
      <div className="select-all">
        <input
          type="checkbox"
          checked={checkAll}
          onChange={(e) => handleCheckAll(e)}
        ></input>
        <p>Select all highlights</p>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="highlights-list">
          {_.map(filteredHighlights, (h, i) => (
            <Highlight i={i} hlt={h} key={`highlights-` + i} />
          ))}
        </ul>
      )}
    </>
  );
}
