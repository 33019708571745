import { useContext } from "react";
import Button from "./Button";

import Heading from "./Heading";
import Rectangle from "./Rectangle";

import Steps from "./Steps";
import TFBLogo from "./TFBLogo";
import VeoUrlForm3 from "./VeoUrlForm3";
import { GeneralContext } from "../App";
import axios from "axios";

export default function ThirdPage() {
  const { onSetClientMail, clientMail, selectedHighlights, videoUrl, reset } =
    useContext(GeneralContext);
  const url = "https://api.thefootballbrain.app/veo/generate_veo_video_summary";

  function handleSubmit(e) {
    e.preventDefault();
    if (!clientMail) return;

    // const payload = {
    //   highlights: [...selectedHighlights],
    //   client_email: clientMail,
    //   video_url: videoUrl,
    // };
    // console.log(payload);

    axios
      .post(url, {
        highlights: [...selectedHighlights],
        client_email: clientMail,
        video_url: videoUrl,
      })
      .then((res) =>
        res ? alert("highlights sent successfully") : alert("error")
      )
      .catch((err) => console.log(err.message));

    onSetClientMail("");
    reset();
  }

  return (
    <div className="first-page third-page">
      <TFBLogo />
      <Rectangle>
        <Heading />
        <Steps />
        <VeoUrlForm3
          onSubmit={handleSubmit}
          placeholder={"Enter your email address"}
        >
          <Button type="submit" className={"btn btn-show-desktop"}>
            See your highlight clip in The Football Brain app
          </Button>
          <Button type="submit" className={"btn btn-show-mobile"}>
            See your highlight clip in TFB app
          </Button>
        </VeoUrlForm3>
      </Rectangle>
    </div>
  );
}
