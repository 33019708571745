import { useContext } from "react";
import Button from "./Button";
import Heading from "./Heading";
import HighlightsList from "./HighlightsList";
import Steps from "./Steps";
import TFBLogo from "./TFBLogo";
import VideoPlayer from "./VideoPlayer";
import FilterHighlights from "./FilterHighlights";
import _ from "lodash";

import { GeneralContext } from "../App";
import Loader from "./Loader";

export default function SecondPage() {
  const { onNextStep, highlights, isLoading } = useContext(GeneralContext);

  const anyItemSelected = _.find(highlights, (el) => el.checked);

  function handleNext(e) {
    e.preventDefault();
    if (anyItemSelected) onNextStep();
  }

  return (
    <div className="second-page">
      <div className="nav">
        <TFBLogo color="black" />
        <Heading />
        <Steps />
      </div>
      <div className="main-second-page">
        <VideoPlayer />
        <div className="highlights">
          <FilterHighlights />
          <Button
            onClick={handleNext}
            className={`${anyItemSelected ? "btn-green btn " : "btn btn-grey"}`}
          >
            {anyItemSelected ? "Next Step" : "Please select highlights"}
          </Button>

          <HighlightsList />
        </div>
      </div>
    </div>
  );
}
